import React from 'react';
import { Box, Avatar } from '@mui/material';
import { PermIdentityRounded } from '@mui/icons-material';
import { Subtitle2Typography } from '../themes/PortalStyling';

const truncateCharLimit = 26;

const ProfileBox = ({ email }) => {
    // setting the email to empty string if it is null or undefined, otherwise, while loading, .length will throw an error
    const displayEmail = email ? (email.length > truncateCharLimit ? email.substring(0, truncateCharLimit) + '...' : email) : '';
    return (
        <Box
            sx={{
                width: '100%',
                padding: '16px',
                backgroundColor: 'transparent',
                border: '1px solid #A5A5A5',
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 2,
                mb: 2
            }}
        >
            <Avatar sx={{ bgcolor: '#A5A5A5', marginRight: '16px' }}>
                <PermIdentityRounded sx={{ color: '#fff' }} />
            </Avatar>
            <Subtitle2Typography
                sx={{ color: '#333', mt: 2, mb: 2 }}
                title={email}
                onDoubleClick={() => navigator.clipboard.writeText(email)}
            >
                {displayEmail}
            </Subtitle2Typography>
        </Box>
    );
};

export default ProfileBox;