import React, { useState, useEffect } from 'react';
import axios from "axios";
import { TextField, Button, Grid, Link, Box, Card } from '@mui/material';
import LoadingButton from "@mui/lab/LoadingButton";
import SvgIcon from '@mui/material/SvgIcon';
import { Error } from '@mui/icons-material';
import {
  LoginBox,
  BackgroundedCard,
  H4Typography,
  BoxCentered, CaptionLink,
  Subtitle1Typography,
  Body2Typography,
  ErrorMessage
} from '../../themes/PortalStyling';
import Logo from "../../assets/images/elemica_blue_gradient_large.svg";
import {getIdPortalHost} from "../../utils/Common";


const dockHeroImage = require('../../assets/images/hero-images/dock-hero.jpg');

export default function ForgotPassword() {

  const axiosInstance = axios.create();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorSubmitting, setErrorSubmitting] = useState('');
  // responseMessage is set to a default message mostly displayed while loading the real response
  const [responseMessage, setResponseMessage] = useState("We'll send you an email with further instructions.");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailFromUrl = params.get('email');
    if (emailFromUrl) {
      setEmail(emailFromUrl); // Auto-populate the email state if found
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateEmail(email)) {
      setError('');
      const submitData = {
        email: email
      };
        setSubmitting(true)
        axiosInstance
          .post(
              window.location.protocol + "//" + getIdPortalHost() + "/forgot-password",
              submitData,
              {
                  withCredentials: true,
                  headers: {
                      "Accept": "application/json",
                      'Content-Type': 'application/json',
                  }
              }
          )
          .then(response => {
                  setErrorSubmitting('');
                  setResponseMessage(response.data.message);
              }
          )
          .catch((errorResponse) => {
              setSubmitted(false);
              setErrorSubmitting(errorResponse.response?.data?.errorMessage);
          })
          .finally(() => setSubmitting(false));
      setSubmitted(true);
    } else {
      setError('Please enter a valid email address.');
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  return (
    <BackgroundedCard heroImage={dockHeroImage}>
      <LoginBox sx={{ px: 6 }}>
        <Grid container xs display="flex" justifyContent="center" alignItems="center" sx={{ mt: 3, mb: 3 }}>
          <Link to="https://elemica.com/">
            <img src={Logo} alt="Logo" width="80px" height="20px" />
          </Link>
        </Grid>
        <H4Typography align="center">
          Reset Password
        </H4Typography>
        <Subtitle1Typography align="center" paragraph>
          {submitted ? responseMessage : "Enter your email and we'll send you further instructions."}
        </Subtitle1Typography>
        {!submitted && (
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email Address"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!error}
              helperText={error}
            />
            <Box mt={2} mb={3}>
              <LoadingButton type="submit" variant="contained" color="primary"
                fullWidth
                loading={submitting}
                loadingPosition="start"
                startIcon={<></>}
              >
                <span>{submitting ? "Submitting..." : "Submit"}</span>
              </LoadingButton>
            </Box>
          </form>
        )}
        {errorSubmitting &&
          <Card sx={{mt: 3}}>
            <ErrorMessage>
              <Grid container direction="row" wrap="false">
                <SvgIcon sx={{color: "#D11F2F", mr: 1}}>
                  <Error/>
                </SvgIcon>
                <Body2Typography>{errorSubmitting}</Body2Typography>
              </Grid>
            </ErrorMessage>
          </Card>
        }
        {submitted && (
          <Button variant="contained" color="primary" sx={{ mt: 2 }} href="/login">
            Back to Login
          </Button>
        )}
        <BoxCentered sx={{ mt: 3, mb: 2 }}>
          <CaptionLink
            target="_blank"
            rel="noreferrer"
            href="https://elemica.com/legal/privacy/"
          >
            Privacy Policy
          </CaptionLink>
        </BoxCentered>
      </LoginBox>
    </BackgroundedCard>
  );
}