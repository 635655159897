import {useState, useEffect} from 'react';
import { useParams, useNavigate  } from 'react-router-dom';
import axios from "axios";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {
    Grid,
    Link,
    Card,
    Stack,
    TextField,
    InputAdornment,
    IconButton,
    Box,
    Avatar,
    CircularProgress
} from '@mui/material';
import {Error, Visibility, VisibilityOff} from '@mui/icons-material';
import LoadingButton from "@mui/lab/LoadingButton";
import SvgIcon from '@mui/material/SvgIcon';
import {
  LoginBox,
  BackgroundedCard,
  H4Typography,
  BoxCentered,
  CaptionLink,
  ErrorMessage,
  Body2Typography
} from '../../themes/PortalStyling';
import {getIdPortalHost, redirectToDefaultLandingPage, validatePassword} from "../../utils/Common";
import ProfileBox from "../../components/ProfileBox";
import Logo from "../../assets/images/elemica_blue_gradient_large.svg";

const dockHeroImage = require('../../assets/images/hero-images/dock-hero.jpg');

export default function ResetPassword() {

    const { id } = useParams();
    const axiosInstance = axios.create();
    const navigate = useNavigate();
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);
    const [errorSubmitting, setErrorSubmitting] = useState('');
    const [errorGettingEmail, setErrorGettingEmail] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [validatingCode, setValidatingCode] = useState(true);
    const [userEmail, setUserEmail] = useState('');
    const [formValues, setFormValues] = useState({
      "accessKey": id,
      "email": "",
      "newPassword": "",
      "confirmationPassword": ""
  })

  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowConfirmationPassword = () => setShowConfirmationPassword(!showConfirmationPassword);

    const ResetPasswordFormValidationSchema = Yup.object().shape({
      newPassword: Yup.string()
          .required("Password is required")
          .test('password', 'Password does not meet criteria', (value) => validatePassword(value)),
          confirmationPassword: Yup.string()
          .required("Confirmation password is required")
          .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      });

  const formMethods = useForm({
      values: formValues,
      resolver: yupResolver(ResetPasswordFormValidationSchema)
  });

  useEffect(() => {
      axiosInstance.get(`/reset-password/validate/${id}`)
          .then((response) => {
              setUserEmail(response?.data?.email);
          })
          .catch((error) => {
              console.error('Error fetching email:', error);
              setErrorGettingEmail(error.response?.data);
          })
          .finally(() => setValidatingCode(false));
  }, []);


  const handleSubmit = (data) => {
    const submitData = {
      accessKey: data.accessKey,
      password: data.newPassword
    };
    setSubmitting(true)
    axiosInstance
        .post(
            window.location.protocol + "//" + getIdPortalHost() + "/password-reset",
            submitData,
            {
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                }
            }
        )
        .then(response => {
                setErrorSubmitting('');
                redirectToDefaultLandingPage();
            }
        )
        .catch((errorResponse) => {
            setErrorSubmitting(errorResponse.response?.data?.errorMessage);
        })
        .finally(() => setSubmitting(false));
};
    
    return (
        <BackgroundedCard heroImage={dockHeroImage}>
          <LoginBox sx={{ px: 6 }}>
            <Grid container xs display="flex" justifyContent="center" alignItems="center" sx={{ mt: 3, mb: 3 }}>
              <Link to="https://elemica.com/">
                <img src={Logo} alt="Logo" width="80px" height="20px" />
              </Link>
            </Grid>
            <H4Typography align="center">
              Reset Password
            </H4Typography>
            {errorGettingEmail && 
              <Card sx={{mt: 3}}>
                <ErrorMessage>
                  <Grid container direction="row" wrap="false">
                    <SvgIcon sx={{color: "#D11F2F", mr: 1}}>
                      <Error/>
                    </SvgIcon>
                    <Body2Typography>{errorGettingEmail?.errorMessage?.errorMessage}</Body2Typography>
                  </Grid>
                </ErrorMessage>
              </Card>
            }
            {validatingCode  ? (
              <Grid display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress size="3rem" />
              </Grid>
            ) : !errorGettingEmail ? ( // hide form if wrong accessKey
              <FormProvider {...formMethods}>
                <form onSubmit={e => {
                    formMethods.clearErrors()
                    formMethods.handleSubmit(handleSubmit)(e)
                }}
                >
                  <Stack
                    alignItems="center"
                    spacing={2}
                  >
                    <ProfileBox email={userEmail} />
                    <Controller
                      name="newPassword"
                      render={({field, fieldState}) =>
                        <TextField variant="outlined"
                          size="small"
                          label="New Password"
                          fullWidth
                          type={showNewPassword ? 'text' : 'password'}
                          margin="normal"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowNewPassword}
                                  edge="end"
                                >
                                  {showNewPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...field}/>
                      }
                    />
                    <Controller
                      name="confirmationPassword"
                      render={({field, fieldState}) =>
                        <TextField variant="outlined"
                          size="small"
                          label="Confirm Password"
                          fullWidth
                          type={showConfirmationPassword ? 'text' : 'password'}
                          margin="normal"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmationPassword}
                                  edge="end"
                                >
                                  {showConfirmationPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...field}/>
                      }
                    />
                  </Stack>
                  <Box display="block" sx={{
                    backgroundColor: '#B0E0F4',
                    color: 'black',
                    padding: '8px',
                    borderRadius: '4px',
                    my: 2
                  }}>
                    <Grid container alignItems="flex-start">
                      <Grid item xs={1}>
                        <Avatar sx={{
                          bgcolor: '#007CBB',
                          color: 'white',
                          width: 24,
                          height: 24,
                          fontSize: '0.75rem'
                        }}>i</Avatar>
                      </Grid>
                      <Grid item xs={11} sx={{pl: 1}}>
                        <Body2Typography align="left">
                          Password must be new, contain at least 10 characters and 3 of the following
                          attributes:
                        </Body2Typography>
                        <Box>
                          <Body2Typography align="left">- An uppercase letter</Body2Typography>
                          <Body2Typography align="left">- A lowercase letter</Body2Typography>
                          <Body2Typography align="left">- A number</Body2Typography>
                          <Body2Typography align="left">- A special character</Body2Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  {errorSubmitting && <Card sx={{mt: 3}}>
                    <ErrorMessage>
                      <Grid container direction="row" wrap="false">
                        <SvgIcon sx={{color: "#D11F2F", mr: 1}}>
                          <Error/>
                        </SvgIcon>
                        <Body2Typography>{errorSubmitting}</Body2Typography>
                      </Grid>
                    </ErrorMessage>
                  </Card>
                  }
                  <Box mt={2} mb={3}>
                    <LoadingButton type="submit" variant="contained" color="primary"
                      fullWidth disabled={!!errorGettingEmail}
                      loading={submitting}
                      loadingPosition="start"
                      startIcon={<></>}
                    >
                      <span>{submitting ? "Updating Password..." : "Update Password"}</span>
                    </LoadingButton>
                  </Box>
                </form>
              </FormProvider>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => navigate('/forgot-password')}
                >
                  Request a new link
                </LoadingButton>
              </Box>
            )}
            <BoxCentered sx={{ mt: 3, mb: 2 }}>
              <CaptionLink
                target="_blank"
                rel="noreferrer"
                href="https://elemica.com/legal/privacy/"
              >
                Privacy Policy
              </CaptionLink>
            </BoxCentered>
          </LoginBox>
        </BackgroundedCard>
      );
    }