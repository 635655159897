export const DEFAULT_LANDING_PAGE = "/home"

export const ENVIRONMENTS = {
    LOCAL: "LOCAL",
    DEV: "DEV",
    STG: "STG",
    PRD: "PRD"
}

export function getEnvironment() {
    const hostname = window.location.hostname;
    if (hostname.includes("localhost")) {
        return ENVIRONMENTS.LOCAL;
    } else if (hostname.includes("dev")) {
        return ENVIRONMENTS.DEV
    } else if (hostname.includes("test")) {
        return ENVIRONMENTS.STG
    } else {
        return ENVIRONMENTS.PRD
    }
}

export function getIdPortalHost() {
    const environment = getEnvironment();
    switch (environment) {
        case ENVIRONMENTS.LOCAL:
            return "localhost:9000";
        case ENVIRONMENTS.DEV:
            return "id.dev-dublin-dev.elemica2.com";
        case ENVIRONMENTS.STG:
            return "id.test.elemica.com";
        case ENVIRONMENTS.PRD:
            return "id.elemica.com";
        default:
            return "id.dev-dublin-dev.elemica2.com";
    }
}

export function getNetworkPortalHost() {
    const environment = getEnvironment();
    switch (environment) {
        case ENVIRONMENTS.LOCAL:
            return "localhost:8080";
        case ENVIRONMENTS.DEV:
            return "qlp.dev-dublin-dev.elemica2.com";
        case ENVIRONMENTS.STG:
            return "my.test.elemica.com";
        case ENVIRONMENTS.PRD:
            return "network.elemica.com";
        default:
            return "qlp.dev-dublin-dev.elemica2.com";
    }
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function redirectToDefaultLandingPage() {
    window.location.href = window.location.protocol + "//" + getNetworkPortalHost() + DEFAULT_LANDING_PAGE;
}

export function getOauth2RedirectUrl(oauth2ClientName) {
    return window.location.protocol + "//" + getIdPortalHost() + "/oauth2/authorization/" + oauth2ClientName;
}

export function getOauth2ClientNameByEmail(axiosInstance, email) {
    return axiosInstance
        .post(
            window.location.protocol + "//" + getIdPortalHost() + "/identity-provider-name-by-email",
            { email: email },
    );
}

export function validatePassword(password) {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return password.length >= 10 && [hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar].filter(Boolean).length >= 3;
  };
